@import "src/styles/main";

.remember-background {
  background-color: $color-gray-background;

  .remember {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    column-gap: 20px;
    margin: 100px auto 0;

    max-width: 1200px;
    padding: 80px 0;

    &__image {
      img {
        height: 50%;
        width: fit-content;
      }
    }

    &__content {
      &--title {
        font-size: 20px;
        font-weight: bold;
        line-height: 25px;
      }

      &--subtitle {
        font-size: 24px;
        font-weight: 600;
        font-family: $font-Montserrat-Regular;
        line-height: 30px;
        padding: 10px 0 24px;
      }

      &--text {

      }
    }
  }
}

@media (max-width: map-get(map-get($break-points, sm), max-width)) {
  .remember-background {
    .remember {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, 1fr);

      &__image {
        justify-self: center;
      }

      &__content {
        &--title {

        }

        &--subtitle {

        }

        &--text {

        }
      }
    }
  }
}