@import "src/styles/main";

.generic-button {
  background-color: $color-blue;
  border: none;
  border-radius: 4px;
  color: $color-white;
  display: inline-block;
  font-family: $font-Montserrat-Medium;
  font-size: 20px;
  padding: 8px 25px;
  transition-duration: 0.6s;
  width: fit-content;

  &:hover {
    background-color: $color-blue-hover;
  }

  span {
    i {
      position: relative;
      top: -2px;
      vertical-align: middle;

      &:first-child {
        margin-right: 5px;
      }

      &:last-child {
        margin-left: 5px;
      }
    }
  }
}

@media (max-width: map-get(map-get($break-points, sm), max-width)) {
  .generic-button {
    padding: 6px 10px;
    font-size: 18px;
  }
}
