@import "src/styles/main";

.search-select {
  align-items: center;
  background-color: $color-gray-background;
  cursor: pointer;
  display: flex;
  position: relative;
  font-size: 14px;
  height: 56px;
  padding: 16px 10px;

  &:first-child {
    span input {
      width: 150px;
    }
  }
  &:nth-child(2) {
    span input {
      width: 150px;
    }
  }
  &:nth-child(3) {
    span input {
      width: 150px;
    }
  }
  &:nth-child(4) {
    span input {
      width: 150px;
    }
  }
  &:nth-child(5) {
    span input {
      width: 150px;
    }
  }

  span {
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-template-rows: 100%;

    i {
      font-size: 20px;
      &:first-child {
        color: $color-blue;
        padding-right: 5px;
      }
      &:last-child {
        color: $color-gray;
      }
    }
    input {
      color: $color-gray;
      display: block;
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__options {
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.16);
    max-height: 200px;
    left: 0;
    position: absolute;
    top: 45px;
    width: 100%;
    overflow-y:auto;
    z-index: 100;

    div {
      padding: 8px 16px;

      &:hover {
        background-color: $color-gray-background;
      }
    }

    &__selected {
      background-color: $color-gray-background;
    }
  }
}

// @media (min-width: map-get(map-get($break-points, xl), min-width)) {
//   .search-select {
//     &:nth-child(1),
//     &:nth-child(2),
//     &:nth-child(3),
//     &:nth-child(4),
//     &:nth-child(5) {
//       span {
//         width: 100%;

//         span {
//           width: auto;
//         }
//       }
//     }
//   }
// }

@media (max-width: map-get(map-get($break-points, md), max-width)) {
  .search-select {
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5) {
      span {
        grid-template-columns: repeat(2, auto) min-content;
        width: 100%;

        span {
          width: auto;
        }
      }
    }
  }
}
