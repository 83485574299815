@import "../../../../styles/main";

.box-with-shadow {
    display: flex;
    filter: drop-shadow(0 0 1px gray) drop-shadow(0 10px 24px rgba(0, 0, 0, 0.1));
    justify-content: flex-end;
    margin-bottom: 56px;
    position: relative;

    &--invert {
        justify-content: flex-start;
    }
    &__box {
        max-width: 272px;
        height: 210px;
        background: white;
        border-radius: 5px;
        padding: 15px;
    }
    &__triangle {
        margin-top: 10px;
        height: 0;
        width: 0;
        border: 10px solid transparent;
        border-left-color: white;

        &--invert {
            border-left-color: transparent;
            border-right-color: white;
        }
    }
    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        overflow: hidden;
        border-radius: 5px;
    }
}

@media (max-width: map-get(map-get($break-points, xs), max-width)) {
    .box-with-shadow {
        margin-bottom: 26px;
        &__box {
            height: 120px;
            padding: 9px;
        }
    }
}
