@import "src/styles/main";

.ancestral-items {
  max-width: 1200px;
  padding: 0 15px;
  margin: 0 auto;

  &__title {
    font-family: $font-Montserrat-Bold;
    margin-bottom: 40px;
  }
}
