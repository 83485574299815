@import "src/styles/main";

.image-and-text {
  padding: 0 $padding_container_desktop;

  &__order {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin: auto;
    max-width: 1000px;
    padding-top: 40px;

    &--image {
      img {
        border-radius: 8px;
        width: 100%;
      }
    }

    &--text {
      font-size: 15px;
      line-height: 25px;

      span {
        display: block;
        margin-bottom: 12px;
      }
    }
  }
}

.img-order-odd,
.text-order-pair {
  grid-column: 2/3;
  grid-row: 1/2;
}

.img-order-pair,
.text-order-odd {
  grid-column: 1/2;
  grid-row: 1/2;
}


@media (max-width: map-get(map-get($break-points, sm), max-width)) {
  .image-and-text {
    padding: 0 $padding_container_mobile;
    margin: auto;
    width: fit-content;

    &__order {
      gap: 20px;
      padding-top: 20px;
      grid-template-columns: auto;

      &--image {

      }

      &--text {

      }
    }
  }

  .img-order-odd,
  .img-order-pair {
    grid-column: 1/2;
    grid-row: 1/2;
  }
  .text-order-pair,
  .text-order-odd {
    grid-column: 1/2;
    grid-row: 2/3;
  }
}