@font-face {
  font-family: 'Montserrat-Bold';
  src: url('../../src/assets/fonts/Montserrat-Bold.ttf') ;
}

@font-face {
  font-family: 'Montserrat-Medium';
  src: url('../../src/assets/fonts/Montserrat-Medium.ttf');
}

@font-face {
  font-family: 'Montserrat-Regular';
  src: url('../../src/assets/fonts/Montserrat-Regular.ttf');
}

@font-face {
  font-family: 'Montserrat-SemiBold';
  src: url('../../src/assets/fonts/Montserrat-SemiBold.ttf');
}

@font-face {
  font-family: 'Montserrat-Light';
  src: url('../../src/assets/fonts/Montserrat-Light.ttf');
}

@font-face {
  font-family: 'Montserrat-ExtraLight';
  src: url('../../src/assets/fonts/Montserrat-ExtraLight.ttf');
}

$font-Montserrat-Bold: Montserrat-Bold, serif;
$font-Montserrat-Medium: Montserrat-Medium, serif;
$font-Montserrat-Regular: Montserrat-Regular, serif;
$font-Montserrat-SemiBold: Montserrat-SemiBold, serif;
$font-Montserrat-Light: Montserrat-Light, serif;
$font-Montserrat-ExtraLight: Montserrat-ExtraLight, serif;