@import "src/styles/main";

.peculiarities {
  text-align: center;
  padding: 20px 30px 50px;
  width: 100%;

  &__title {
    color: var(--indigo);
    font-size: xx-large;
    font-weight: bold;
    font-family: $font-Montserrat-Regular;
  }
  &__subtitle {
    font-family: $font-Montserrat-Regular;
    font-size: $subtitle_desktop;
    padding: 20px 0;
    span {
      display: block;
    }
  }
  &__second-subtitle {
    font-size: 18px;
    span {
      display: block;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
    text-align: center;
    padding: 50px 0 100px;

    &--card {
      width: 324px;
      border-radius: 6px;
      box-shadow: 0 10px 20px 0 rgba(0,0,0,0.2);
      padding: 20px;
      font-size: 14px;

      .peculiarities__card {
        padding: 0 20px;
        &--img {
          img {
            max-width: 200px;
          }
        }

        &--title {
          font-family: $font-Montserrat-Medium;
          padding: 10px 0;
        }

        &--text {
          font-size: 14px;
          letter-spacing: 0;
          line-height: 20px;
        }
      }

      &:nth-child(2),
      &:nth-child(5),
      &:nth-child(8),
      &:nth-child(11),
      &:nth-child(14) {
        top: 100px;
        position: relative;
      }
    }
  }
}

@media (max-width: map-get(map-get($break-points, sm), max-width)) {
  .peculiarities {
    &__title {
      font-size: $title_mobile;
    }
    &__subtitle {
      font-size: $subtitle_mobile;
    }
    &__second-subtitle {
      font-size: 15px;
    }

    &__list {
      grid-template-columns: 1fr;
      &--card {
        width: fit-content;

        .peculiarities__card {

          &--img {
            width: 100%;

            img {
              width: inherit;
            }
          }

          &--title {

          }

          &--text {

          }
        }

        &:nth-child(2),
        &:nth-child(5),
        &:nth-child(8),
        &:nth-child(11),
        &:nth-child(14) {
          top: 0;
        }
      }
    }
  }
}