@import "src/styles/main";

.volunteering {
  background: $color-gray-background;
  padding: 90px 25px 110px;

  &__places {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    gap: 25px;
    max-width: 1200px;
    margin: 0 auto 60px;
    flex-wrap: wrap;
    padding: 0 25px;
  }
}
