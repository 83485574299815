@import "src/styles/main";

.information-university {
  margin: 0 auto;
  padding: 40px 0;
  width: 80%;

  &__name {
    font-family: $font-Montserrat-Bold;
    font-size: 30px;
  }
  &__address {
    font-size: 20px;
  }
  &__phone_number {
    font-size: 20px;
  }
  &__description {
    padding-top: 10px;
    font-size: 16px;
    line-height: 25px;
    text-align: justify;
  }
  &__whatsapp {
    span {
      color: $color-pink-link;
      cursor: pointer;
      font-size: 16px;
    }
  }
  &__logo {
    text-align: center;
  }
}
