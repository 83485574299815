@import "src/styles/main";

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.64);
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px 35px;

  &__content {
    padding: 50px 50px 50px 40px;
    background: $color-white;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;
    ::-webkit-scrollbar {
      width: 7px;
    }
    ::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0.2);
      border-radius: 4px;
    }
    ::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.3);
      border-radius: 4px;
    }
    &--full {
      padding: 50px 10px 50px 40px;
      height: 100%;
    }
  }
  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 30px;
    background: none;
    cursor: pointer;
    border: none;
  }
}

@media (max-width: map-get(map-get($break-points, sm), min-width)) {
  .modal-container {
    padding: 15px 25px;
  }
}
