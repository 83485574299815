@import "../../../../styles/main";

.timeline-event {
  position: relative;
  padding-left: 10px;
  margin-bottom: 56px;
  &--invert {
    text-align: right;
    padding-left: 0;
    padding-right: 10px;
  }
  &__title {
    font-family: $font-Montserrat-Bold;
    font-size: $title_desktop;
  }
  &__content {
    font-size: 1.12rem;
    line-height: 1.7;
    font-family: $font-Montserrat-Regular;
    a {
      color: $color-pink-link;
    }
  }
  &__circle {
    width: 47px;
    height: 47px;
    border-radius: 50%;
    background: $color-yellow;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: calc(-34px - 24.5px);

    &--invert {
      left: initial;
      right: calc(-34px - 23.5px);
    }
  }
  &__point {
    width: 65.957446808%;
    height: 65.957446808%;
    border: 5px solid white;
    border-radius: 50%;
  }
}

@media (max-width: map-get(map-get($break-points, xs), max-width)) {
  .timeline-event {
    padding-left: 5px;
    margin-bottom: 26px;
    &--invert {
      padding-right: 5px;
    }
    &__title {
      font-size: 1.2rem;
    }
    &__content {
      font-size: 0.9rem;
    }
    &__circle {
      width: 26px;
      height: 26px;
      left: calc(-18px - 13px);

      &--invert {
        left: initial;
        right: calc(-17px - 13px);
      }
    }
    &__point {
      border: 3px solid white;
    }
  }
}

@media (max-width: map-get(map-get($break-points, xxs), max-width)) {
  .timeline-event {
    padding-left: 0;
    &--invert {
      padding-right: 0;
    }
    &__title {
      font-size: 1.1rem;
    }
    &__content {
      font-size: 0.85rem;
    }
    &__circle {
      width: 24px;
      height: 24px;
      left: calc(-14px - 12px);

      &--invert {
        left: initial;
        right: calc(-13px - 12px);
      }
    }
    &__point {
      width: 14px;
      height: 14px;
      border: 2px solid white;
    }
  }
}
