@import "src/styles/main";

.public-employment-service {
  padding: 40px 0;
  text-align: center;

  &__title {
    font-weight: bold;
    font-size: 18px;
  }

  &__subtitle {
    font-family: $font-Montserrat-Medium;
    font-size: $title_desktop;
  }

  &__second_subtitle {
    font-weight: bold;
  }

  &__options {
    padding-top: 30px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    grid-gap: 30px;
    max-width: 1200px;
    margin: auto;

    .options-employment {
      border-radius: 4px;
      border: $color-gray-light 1px solid;
      padding: 24.5px;
      max-width: 364px;
      text-align: left;

      &__img {
      }
      &__title {
        margin: 25px 0;
        span {
          font-family: $font-Montserrat-Medium;
          font-size: 20px;
        }
      }
      &__text {
        font-size: 0.75rem;
        line-height: 1.666;
        font-family: $font-Montserrat-Regular;
        a {
          color: $color-pink-link;
        }
      }
    }
  }
}

@media (max-width: map-get(map-get($break-points, md), max-width)) {
  .public-employment-service {
    &__options {
      grid-template-columns: repeat(2, 1fr);
      .options-employment {
      }
    }
  }
}

@media (max-width: map-get(map-get($break-points, sm), max-width)) {
  .public-employment-service {
    &__options {
      grid-template-columns: 1fr;
      .options-employment {
      }
    }
  }
}
