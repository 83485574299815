@import "src/styles/main";

.travel {
  max-width: 1200px;
  justify-items: center;
  margin: auto;
  text-align: center;
  padding: 100px $padding_container_desktop;

  &__title {
    font-family: $font-Montserrat-Regular;
    font-size: $title_desktop;
    padding-bottom: 20px;
  }

  &__subtitle {
    font-family: $font-Montserrat-Regular;
    font-size: $subtitle_desktop;
    padding-bottom: 25px;

    span {
      display: block;
    }
  }

  &__text {
    line-height: 20px;
    padding-bottom: 25px;

    span {
      display: block;
    }
  }
}

@media (max-width: map-get(map-get($break-points, sm), max-width)) {
  .travel {
    padding: 0 $padding_container_mobile;
  }
}