@import "src/styles/main";

.internship {
  &__hero {
    height: 600px;
    display: grid;
    place-content: center;
    margin-bottom: 90px;
  }
  &__title {
    color: $color-white;
    font-size: 3.1875rem;
    line-height: 1.19;
    font-family: $font-Montserrat-Bold;
  }
}

@media (max-width: map-get(map-get($break-points, xs), max-width)) {
  .internship__hero {
    height: 300px;
  }
}
