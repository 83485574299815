@import "../../../../styles/main";

.city {
  position: relative;
  border-radius: 7px;
  overflow: hidden;
  cursor: pointer;

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.4s ease;
  }
  &__container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    color: white;
    background: rgba(0, 0, 0, 0.35);
    padding: 30px;
  }
  &__name {
    font-size: 2rem;
    line-height: 1.2;
    font-family: $font-Montserrat-Bold;
  }
  &__offer {
    margin: 0;
  }

  &:hover {
    .city__image {
      transform: scale(1.15);
    }
  }
}

.little {
  grid-column: span 1;
}
.big {
  grid-column: span 2;
}

@media (max-width: map-get(map-get($break-points, md), max-width)) {
  .little {
    grid-column: span 2;
  }
  .big {
    grid-column: span 3;
  }
}
@media (max-width: map-get(map-get($break-points, sm), max-width)) {
  .big {
    grid-column: span 1;
  }
  .little {
    grid-column: span 1;
  }
}
