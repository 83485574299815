@import "src/styles/main";

.want-to-work {
  max-width: 700px;
  margin: 0 auto;
  padding: 90px 25px;
  color: black;
  text-align: center;

  button {
    margin-top: 20px;
  }
}

.modal-overflow {
  overflow-y: auto;
}
