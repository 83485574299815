@import "src/styles/main";

.link-navigation {
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
  padding: 0 35px;
  position: relative;

  > span {
    text-align: center;
    white-space: nowrap;
    .active {
      color: $color-yellow;
    }
  }
}
#links.active,
#links:hover {
  color: $color-yellow;
}
#links:hover > #options {
  display: block;
}

@media (max-width: map-get(map-get($break-points, lg), max-width)) {
  .link-navigation {
    padding: 0 30px;
  }
}
