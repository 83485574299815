@import "src/styles/main";

.hosting-service {
  margin: auto;
  text-align: center;
  padding: 130px $padding_container_desktop;

  &__title {
    font-family: $font-Montserrat-Regular;
    font-size: $title_desktop;
    padding-bottom: 20px;
  }

  &__subtitle {
    font-family: $font-Montserrat-Regular;
    font-size: $subtitle_desktop;
    padding-bottom: 55px;

   span {
     display: block;
   }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3, auto);
    justify-content: space-between;
    max-width: 1200px;
    justify-items: center;
    margin: auto;
  }
}

@media (max-width: map-get(map-get($break-points, md), max-width)) {
  .hosting-service {
    padding: 80px $padding_container_mobile;

    &__list {
      display: grid;
      row-gap: 20px;
      grid-template-columns: repeat(2, auto);

      .box-information-simple:last-child {
        grid-column: 1/3;
        justify-self: center;
      }
    }
  }
}

@media (max-width: map-get(map-get($break-points, sm), max-width)) {
  .hosting-service {
    padding: 30px $padding_container_desktop;

    &__title {
      font-size: $title_mobile;
    }

    &__subtitle {
      font-size: $subtitle_mobile;
      padding-bottom: 20px;
    }

    &__list {
      display: grid;
      row-gap: 20px;
      grid-template-columns: auto;
      justify-content: center;

      .box-information-simple:last-child {
        grid-column: 1/2;
        justify-self: center;
      }
    }
  }
}