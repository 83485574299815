@import "src/styles/main";

.gastronomy {
  text-align: center;

  &__title {
    font-size: $title_desktop;
    font-family: $font-Montserrat-Regular;
  }
  &__subtitle {
    font-family: $font-Montserrat-Regular;
    font-size: $subtitle_desktop;
    padding: 20px 0;
    span {
      display: block;
    }
  }
  &__second-subtitle {
    font-size: 18px;
    span {
      display: block;
    }
  }
}

@media (max-width: map-get(map-get($break-points, sm), max-width)) {
  .gastronomy {
    &__title {
      font-size: $title_mobile;
    }
    &__subtitle {
      font-size: $subtitle_mobile;
    }
    &__second-subtitle {
      font-size: 15px;
    }
  }
}