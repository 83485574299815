@import "src/styles/main";

.filters-container {
  max-width: 1200px;
  padding: 0 25px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  margin-top: 40px;
}

.filters__clear {
  height: 32px;
  min-width: 32px;
  border-radius: 50%;
  border: 1px solid $color-gray;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
}

.filters__checkbox {
  margin: 0;
  margin-left: 10px;
  margin-right: 20px;
  position: relative;
  cursor: pointer;

  &::before {
    position: absolute;
    left: calc(100% + 4px);
    top: 7px;
    content: "";
    height: 16px;
    width: 16px;
    border: 1px solid $color-gray;
  }
  &::after {
    position: absolute;
    left: calc(100% + 7px);
    top: 11px;
    content: "";
    width: 10px;
    height: 5px;
    border-left: 2px solid white;
    border-bottom: 2px solid white;
    transform: rotate(-45deg);
  }

  &--checked {
    &::before {
      background: $color-yellow;
      border: 1px solid $color-yellow;
    }
  }
}

@media (max-width: map-get(map-get($break-points, md), max-width)) {
  .filters-container {
    flex-wrap: wrap;
  }
  .select {
    max-width: calc(33% - 10px);
    margin: 5px !important;
  }
  .filters__clear {
    margin: 10px auto 5px;
  }
  .filters__checkbox {
    display: none;
  }
}
@media (max-width: map-get(map-get($break-points, sm), min-width)) {
  .select {
    max-width: calc(50% - 10px);
  }
}
