@import "src/styles/main";

.learn-spanish__hero {
  height: 768px;
}

@media (max-width: map-get(map-get($break-points, xs), max-width)) {
  .learn-spanish__hero {
    height: 300px;
  }
}
