@import "src/styles/main";

.tips {
  max-width: 1200px;
  justify-items: center;
  margin: auto;
}

.tip_full_content {
  max-height: 60vh;
  overflow-y: auto;
  padding: 30px;
}