@import "src/styles/main";

.employment-service {
  max-width: 1050px;
  margin: 0 auto;
  padding: 65px 25px 90px;

  &__card {
    max-width: 270px;
    margin: 0 auto;
  }
}
