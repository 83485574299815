@import "src/styles/main";

.under-construction {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 80px 20px 80px;

  &__title {
    font-family: $font-Montserrat-Bold;
    font-size: 3.12rem;
    line-height: 1.35;
    max-width: 712px;
    margin: 0 auto;
    margin-bottom: 4px;
    white-space: pre-line;
  }
  &__subtitle {
    font-family: $font-Montserrat-Bold;
    font-size: 2.12rem;
    line-height: 1.35;
    max-width: 712px;
    margin: 0 auto;
    margin-bottom: 28px;
  }
  &__image {
    width: 52%;
    margin-left: 20px;
  }
}

@media (max-width: map-get(map-get($break-points, md), max-width)) {
  .under-construction {
    flex-direction: column;
    text-align: center;
    padding: 60px 20px;
    &__image {
      width: 70%;
    }
  }
}
@media (max-width: map-get(map-get($break-points, sm), max-width)) {
  .under-construction {
    padding: 40px 20px;
    &__image {
      width: 100%;
    }
  }
}
