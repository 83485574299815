@import "src/styles/main";

.logo {
  cursor: pointer;
}

.banner {
  color: $color-white;
  display: flex;
  justify-content: space-between;
  padding: 20px $padding_container_desktop 0;
}

.roles {
  display: grid;
  font-weight: bold;
  grid-template-columns: auto auto auto;
  grid-template-rows: min-content;
  width: min-content;
}

.role {
  cursor: pointer;
  border-color: $color-white;
  border-style: solid;
  border-width: 1px 0 1px 0;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  padding: 5px 15px;
  width: min-content;

  &__off {
    cursor: default;
  }
}

.border-left-role {
  border: 1px solid $color-white;
  border-radius: 5px 0 0 5px;
  padding: 5px 15px;
}

.border-right-role {
  border: 1px solid $color-white;
  border-radius: 0 5px 5px 0;
  padding: 5px 15px;
}

.user {
  align-items: center;
  display: grid;
  grid-column-gap: 24px;
  grid-template-columns: auto auto auto auto;
  grid-template-rows: min-content;
  justify-content: end;

  &__search {
    font-size: 25px;
  }

  &__language-button {
    align-items: center;
    background-color: $color-white;
    border-radius: 5px;
    color: $color-blue;
    cursor: pointer;
    display: flex;
    font-family: $font-Montserrat-Regular;
    font-size: 12px;
    height: 24px;
    position: relative;
    width: 24px;
    justify-content: center;

    &--list {
      background-color: $color-white;
      border-radius: 4px;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.16);
      display: none;
      position: absolute;
      z-index: 3;
      right: 0;
      text-align: right;
      top: 25px;
      width: 129px;
      overflow: hidden;

      span {
        color: $color-black;
        display: block;
        padding: 5px 18px;

        &:first-child {
          margin-bottom: 5px;
        }

        &:hover {
          background-color: $color-gray-background;
        }
      }
    }
  }

  &__login {
    cursor: pointer;
    display: flex;

    span {
      font-family: $font-Montserrat-Medium;
      font-size: 16px;
      margin-right: 10px;
    }

    i {
      height: 30px;
      width: 30px;
      color: $color-blue;
      font-size: 30px;
      border-radius: 50%;
      background-color: #ffffff;
      margin-left: 10px;
    }
  }
}

.menu-button {
  border: none;
  background: none;
  outline: none;
  color: $color-white;
  font-size: 20px;
  display: none;
}

#selectLanguage:hover > #optionsLanguages {
  display: block;
}

.role-selected {
  background-color: $color-white;
  color: $color-blue;
  font-family: $font-Montserrat-Regular;
}

@media (max-width: map-get(map-get($break-points, lg), min-width)) {
  .banner {
    padding-bottom: 20px;
  }
  .roles {
    display: none;
    &--show {
      display: grid;
    }
  }
  .menu-button {
    display: block;
  }
}

@media (max-width: map-get(map-get($break-points, sm), max-width)) {
  .banner {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 10px $padding_container_mobile;
  }

  .role {
    font-size: 12px;
    padding: 5px 5px;
  }

  .roles {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
    margin: 0 auto;
    padding-top: 10px;
  }

  .user {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  .logo {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
  }
}
