@import "src/styles/main";

.higher-education__hero {
  height: 600px;
  display: grid;
  place-content: center;
  margin-bottom: 90px;
}

.btn-play {
  color: $color-white;
  border: 3px solid;
  padding: 5px;
  border-radius: 50%;
  background: none;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  outline: 0;
}

@media (max-width: map-get(map-get($break-points, xs), max-width)) {
  .higher-education__hero {
    height: 300px;
  }
}
