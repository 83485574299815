@import "src/styles/main";

.card-modal {
  margin: 13px;
  width: calc(33.33% - 26px);
  padding: 24.5px;
  border-radius: 4px;
  border: 1px solid $color-gray-light;
  cursor: pointer;

  &__image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  &__title {
    margin: 40px 0 30px;
    font-size: 1.5rem;
    line-height: 1.25;
    font-family: $font-Montserrat-SemiBold;
  }
  &__content {
    font-size: 0.75rem;
    line-height: 1.666;
    font-family: $font-Montserrat-Regular;
    a {
      color: $color-pink-link;
    }
  }
  &__detail {
    overflow: auto;
    a {
      color: $color-pink-link;
    }
  }
}

@media (max-width: map-get(map-get($break-points, lg), max-width)) {
  .card-modal {
    margin: 13px;
    width: calc(50% - 26px);
  }
}

@media (max-width: map-get(map-get($break-points, xs), max-width)) {
  .card-modal {
    width: 100%;
  }
}
