@import "../../../../styles/main";

.slick-dots {
  list-style: none;
  display: flex !important;
  justify-content: center;
  margin-top: 70px;
  padding: 0;
}
.slick-dots li,
.slick-dots li button {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  padding: 0;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  padding: 5px;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.slick-dots li button:before {
  font-family: slick;
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  background: black;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  content: "";
  text-align: center;
  opacity: 0.25;
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  width: 12px;
  height: 12px;
  opacity: 1;
  top: -2px;
}

.slide-container {
  padding: 0 24px;
}
.scholarship-slide {
  > div {
    width: 270px;
  }
}

@media (max-width: map-get(map-get($break-points, sm), max-width)) {
  .slide-container {
    padding: 0;
  }
}

@media (max-width: map-get(map-get($break-points, xs), max-width)) {
  .scholarship-slide {
    > div {
      width: 100%;
    }
  }
}
