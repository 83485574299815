@import './src/styles/main';

.how-to-start {
  text-align: center;
  padding: 100px $padding_container_desktop;

  &__title {
    padding-bottom: 32px;
    span {
      display: block;
      font-family: $font-Montserrat-Regular;

      &:first-child {
        margin: 8px 0;
        font-size: $title_desktop;
        line-height: 29px;
      }

      &:not(:first-child) {
        font-size: $subtitle_desktop;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 46px;
      }
    }
  }

  &__list {
    display: grid;
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;

    &--step {
      .how-to-start__icon {
        align-items: center;
        background-color: $color-blue-background;
        border-radius: 50%;
        display: grid;
        font-size: 42px;
        height: 88px;
        width: 88px;
        margin: 0 auto;
      }
    }
  }

  &__message {
    font-family: $font-Montserrat-Regular;
    font-size: 24px;
    line-height: 29px;
    padding: 30px 0;
  }

  &__button {
    text-align: center;
  }

  &__icon {
    img {
      position: relative;
      margin: 0 auto;
      width: 70%;
    }
  }
}

@media (max-width: map-get(map-get($break-points, xs), max-width)) {
  .how-to-start {
    padding: 30px $padding_container_mobile;

    &__title {
      span {
        &:first-child {
          font-size: $title_mobile;
        }

        &:not(:first-child) {
          font-size: $subtitle_mobile;
        }
      }
    }

    &__list {
      grid-template-rows: repeat(5, 1fr);
      row-gap: 25px;
    }
  }
}

@media (min-width: map-get(map-get($break-points, sm), min-width)) {
  .how-to-start {
    &__list {
      margin: 20px 0 30px;
      grid-template-columns: repeat(5, 1fr);
    }
  }
}
