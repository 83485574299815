@import "src/styles/main";

.div {
  background-color: red;
}

.footer {
  display: grid;
  grid-template-columns: 5fr 2fr 2fr 2fr;
  grid-template-rows: 1fr auto;
  grid-gap: 10px;
  padding: 0 $padding_container_desktop;
  max-width: 1200px;
  margin: 0 auto;
}

.title {
  color: $color-black-title;
  font-family: $font-Montserrat-Bold;
  font-size: 20px;
  margin-bottom: 30px;
}

.text-list {
  color: $color-gray;
  font-weight: bold;
  display: grid;

  &__cursor span {
    cursor: pointer;
    width: fit-content;
  }
}

.logos {
  display: grid;
  grid-template-rows: repeat(2, 1fr);

  &__min {
    align-items: end;
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 10px;
    grid-template-rows: 100%;
    padding-bottom: 20px;
  }
}

.follow-us {
  &__icon-list {
    i {
      border-radius: 50%;
      border: 0.71px solid $color-gray-hr;
      cursor: pointer;
      font-size: 24px;
      height: 34px;
      margin-right: 5px;
      padding: 5px;
      width: 34px;

      &:nth-child(1) {
        color: $color-blue-facebook;
      }

      &:nth-child(2) {
        border: none;
        background-color: $color-blue-twitter;
        color: $color-white;
      }

      &:nth-child(3) {
        color: $color-red-pinterest;
      }

      &:nth-child(4) {
        color: $color-gray-dark;
      }
    }
  }
}

.copyright {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid $color-gray-hr;
  color: $color-gray-dark;
  font-family: $font-Montserrat-Bold;
  font-size: 14px;
  padding: 15px 0;
  a {
    color: $color-gray;
  }
}

@media (min-width: map-get(map-get($break-points, md), min-width)) {
  .copyright {
    grid-column: 1/5;
  }
}

@media (max-width: map-get(map-get($break-points, md), max-width)) {
  .footer {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto auto;
    padding: 0 15px;
    text-align: center;
  }

  .text-list {
    span {
      justify-self: center;
    }
  }

  .menu {
    grid-column: 1/2;
    grid-row: 2/3;
  }

  .contact-us {
    grid-column: 2/3;
    grid-row: 2/3;
  }

  .title {
    margin-bottom: 10px;
  }

  .logos {
    text-align: center;
    display: block;

    &__min {
      grid-template-columns: 100%;
      grid-template-rows: repeat(2, 1fr);
      row-gap: 10px;
      padding-bottom: 0;
      img {
        justify-self: center;
      }
    }
  }
}

@media (max-width: map-get(map-get($break-points, sm), max-width)) {
  .footer {
    grid-template-columns: 100%;
    grid-template-rows: auto auto auto auto auto;
  }

  .menu {
    grid-column: 1/2;
  }

  .contact-us {
    grid-column: 1/2;
    grid-row: 3/4;
  }
}
