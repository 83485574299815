@import "colors";
@import "constants";
@import "grid";
@import "fonts";

html,
body {
  font-family: $font-Montserrat-ExtraLight;
  line-height: 30px;
  letter-spacing: 0;
}
:root {
  --headerHeight: 115px;
}

.header-background-space {
  background: $color-blue;
  height: var(--headerHeight);
  width: 100%;
}

@media (max-width: map-get(map-get($break-points, lg), min-width)) {
  :root {
    --headerHeight: 80px;
  }
}

@media (max-width: map-get(map-get($break-points, md), max-width)) {
  :root {
    font-size: 15px;
  }
}

@media (max-width: map-get(map-get($break-points, sm), max-width)) {
  :root {
    font-size: 14px;
  }
}
@media (max-width: map-get(map-get($break-points, xs), max-width)) {
  :root {
    font-size: 13.75px;
  }
}
