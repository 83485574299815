@import "src/styles/main";

.cities-u {
  padding: 75px 0 70px;
  &__content {
    padding: 0 25px;
    max-width: 700px;
    margin: 0 auto 100px;
    font-family: $font-Montserrat-SemiBold;
  }
  &__grid {
    display: grid;
    grid-template-columns: 1fr minmax(auto, 600px) minmax(auto, 600px) 1fr;
    grid-template-areas: ". list map map";
    gap: 40px;
  }
  &__list {
    grid-area: list;
  }
  &__map {
    grid-area: map;
    position: sticky;
    top: calc(var(--headerHeight) + 15px);
    height: calc(100vh - var(--headerHeight) - 30px);
    > div {
      height: 100%;
    }
  }
}
@media (max-width: map-get(map-get($break-points, md), max-width)) {
  .cities-u {
    &__grid {
      padding: 0 25px;
      grid-template-columns: 1fr;
      grid-template-rows: auto auto;
      grid-template-areas: "list" "map";
    }
  }
}

@media (max-width: map-get(map-get($break-points, md), min-width)) {
  .cities-u {
    &__map {
      min-height: 370px;
      height: calc(80vh - var(--headerHeight) - 30px);
    }
  }
}
