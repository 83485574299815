@import "src/styles/main";

.about-us {
  text-align: center;
  margin: auto;

  &__title {
    font-family: $font-Montserrat-Regular;
    font-size: $title_desktop;
    margin: 25px 0;
  }
  &__subtitle {
    font-family: $font-Montserrat-Regular;
    font-size: $subtitle_desktop;
    font-weight: bold;
    line-height: 40px;
    margin-bottom: 35px
  }
  &__text {
    font-size: 18px;
    line-height: 25px;

    u {
      color: $color-pink-link;
      cursor: pointer;
      font-family: $font-Montserrat-Regular;
      margin-left: 10px;
    }
  }
  &__message {
    font-family: $font-Montserrat-Medium;
    font-size: 24px;
    margin: 10px 0 35px;
    text-decoration-line: underline $font-Montserrat-Regular;
  }
}

@media (max-width: map-get(map-get($break-points, sm), max-width)) {
  padding: 0 $padding_container_mobile;

  .about-us {
    &__text {
      font-size: 15px;
    }

    &__subtitle {
      font-size: $subtitle_mobile;
    }
  }
}