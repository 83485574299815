@import "../../../styles/main";

.opportunities {
  max-width: 1200px;
  margin: 120px auto;
  padding: 0;
}

@media (max-width: map-get(map-get($break-points, lg), max-width)) {
  .opportunities {
    padding: 0 25px;
    margin: 100px auto;
  }
}
@media (max-width: map-get(map-get($break-points, lg), max-width)) {
  .opportunities {
    padding: 0 15px;
    margin: 70px auto;
  }
}
