@import "src/styles/main";

.characteristics {
  &__title {
    font-size: $title_desktop;
    font-family: $font-Montserrat-Regular;
  }
  &__subtitle {
    font-family: $font-Montserrat-Regular;
    font-size: $subtitle_desktop;
    padding: 20px 0;
    span {
      display: block;
    }
  }
  &__second-subtitle {
    font-size: 18px;
    span {
      display: block;
    }
  }
}

.culture-characteristics {
  padding: 40px 0;
  text-align: center;

  &__title {
    font-weight: bold;
    font-size: 18px;
  }

  &__subtitle {
    font-family: $font-Montserrat-Medium;
    font-size: $title_desktop;
  }

  &__second_subtitle {
    font-weight: bold;

  }

  &__options {
    padding-top: 30px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    grid-gap: 30px;
    max-width: 1200px;
    margin: auto;
  }

  &__list {
    border-radius: 6px;
    border: $color-gray-light 1px solid;
    padding: 40px;
    max-width: 364px;
    text-align: center;
    width: 350px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);

    &--img {
      img {
        max-width: 200px;
      }
    }

    &--title {
      margin: 25px 0;
      span {
        font-family: $font-Montserrat-Medium;
        font-size: 20px;
        font-weight: bold;
      }
    }
    &--text {
      font-size: 14px;
      line-height: 15px;
    }
  }
}

@media (max-width: map-get(map-get($break-points, md), max-width)) {
  .culture-characteristics {
    &__options {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media (max-width: map-get(map-get($break-points, sm), max-width)) {
  .culture-characteristics {
    &__list {
      max-width: 100vw;

      &--img {
        width: 100%;

        img {
          width: inherit;
        }
      }
    }

    &__options {
      grid-template-columns: 1fr;
      padding: 30px;
    }
  }

  .characteristics {
    &__title {
      font-size: $title_mobile;
    }
    &__subtitle {
      font-size: $subtitle_mobile;
    }
    &__second-subtitle {
      font-size: 15px;
    }
  }
}