@import "../../../styles/main";

.contact {
  display: flex;
  margin-bottom: 50px;
  &__image {
    width: 50%;
    object-fit: cover;
  }
  &__form {
    padding: 140px 140px 92px 30px;
  }
  &__us {
    font-size: 1.5rem;
    line-height: 1.25;
    font-family: $font-Montserrat-SemiBold;
    color: $color-black;
  }
  &__question {
    font-size: 2.125rem;
    font-family: $font-Montserrat-Bold;
    line-height: 1.25;
    color: $color-black;
  }
  &__instructions {
    font-size: 1.125rem;
    font-family: $font-Montserrat-Regular;
    line-height: 1.333;
    color: $color-black;
  }
  &__input {
    border-radius: 5px;
    height: 50px;
    width: 100%;
    border: 1px solid $color-gray-input;
    padding: 15px;
    margin-bottom: 15px;
    outline: none;
    color: $color-blue;
    font-family: $font-Montserrat-Regular;

    &:focus,
    &:active {
      border: 1px solid $color-yellow-slider;
    }

    &::placeholder {
      font-family: $font-Montserrat-Light;
      color: $color-gray-placeholder;
    }

    &--textarea {
      height: 140px;
      margin-top: 15px;
      resize: none;
    }
    &--invalid {
      border: 1px solid red;
    }
  }
  &__button {
    border: none;
    background: $color-blue;
    color: $color-white;
    cursor: pointer;
    outline: none;
    padding: 15px 75px;
    border-radius: 5px;
    font-family: $font-Montserrat-SemiBold;
    font-size: 1.25rem;
    line-height: 1.2;
  }
  &__message {
    font-size: 1.5rem;
    line-height: 1.25;
    font-family: $font-Montserrat-SemiBold;
    color: $color-black;
    margin-bottom: 20px;
    text-align: center;
  }
}

@media (max-width: map-get(map-get($break-points, xl), max-width)) {
  .contact {
    &__form {
      padding: 140px 80px 92px 30px;
    }
  }
}
@media (max-width: map-get(map-get($break-points, md), min-width)) {
  .contact {
    &__form {
      padding: 110px 50px 72px 30px;
    }
  }
}
@media (max-width: map-get(map-get($break-points, sm), min-width)) {
  .contact {
    flex-direction: column;
    &__form {
      padding: 40px 30px 42px 30px;
    }
    &__image {
      width: 100%;
      order: 2;
      height: 250px;
      object-position: 50% -29px;
    }
    &__button {
      width: 100%;
    }
  }
}

@media (max-width: map-get(map-get($break-points, xs), min-width)) {
  .contact {
    &__form {
      padding: 30px 15px 42px 15px;
    }
  }
}
