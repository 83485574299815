@import "../../../styles/main";

.know-more {
  max-width: 1200px;
  margin: 0 auto;
  padding: 90px 25px 140px;
  h2,
  h3 {
    color: $color-black;
  }
}
