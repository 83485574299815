@import "../../../styles/main";

.mosaic {
  margin: 0 auto 120px;
  &__container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: 350px;
    gap: 24px;
    margin-bottom: 74px;
  }

  &__show-all {
    display: flex;
    justify-content: center;
  }
}
@media (max-width: map-get(map-get($break-points, md), max-width)) {
  .mosaic {
    margin-bottom: 100px;
    &__container {
      grid-template-columns: repeat(5, 1fr);
    }
  }
}
@media (max-width: map-get(map-get($break-points, sm), max-width)) {
  .mosaic {
    margin-bottom: 80px;
    &__container {
      grid-template-columns: 1fr;
      grid-auto-rows: 300px;
    }
  }
}
