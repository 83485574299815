@import "../../../styles/main";

.scholarship {
  margin: 0 auto 76px;

  &__container {
    max-width: 860px;
    margin: 0 auto 30px;
  }

  &__button {
    display: flex;
    justify-content: center;
  }
  &__action {
    display: flex;
    flex-direction: column;
    align-items: center;
    &-title {
      font-size: 1.5rem;
      font-family: $font-Montserrat-SemiBold;
      color: $color-gray-title;
      margin-bottom: 27px;
    }
  }
}
.communication-link {
  font-family: 0.875;
  color: $color-blue;
  font-family: $font-Montserrat-SemiBold;
}

@media (max-width: map-get(map-get($break-points, md), max-width)) {
  .scholarship {
    margin: 0 auto 76px;

    &__container {
      max-width: 572px;
    }
  }
}
