@import "src/styles/main";

.article {
  align-items: center;
  border: 1px $color-gray-border solid;
  border-radius: 6px;
  box-shadow: 0 2px 6px 0 rgba(44, 46, 48, 0.11);
  display: grid;
  grid-template-columns: 160px auto;
  min-height: 160px;
  width: 100%;

  &__img {
    height: 158px;
    width: 158px;

    img {
      border-radius: 6px 0 0 6px;
      object-fit: cover;
    }
  }

  &__content {
    display: grid;
    grid-template-rows: min-content auto min-content;
    padding: 8px;
    font-size: 12px;
    text-align: left;
    height: 100%;

    &--title {
      font-family: $font-Montserrat-Regular;
      font-size: 20px;
      line-height: 24px;
    }

    &--text {
      display: block;
      letter-spacing: 0;
      font-weight: bold;
      margin-top: 5px;
      line-height: 15px;
      color: #5c5f64;
      font-size: 14px;
    }

    &--date {
      display: grid;
      align-items: flex-end;
      color: black;
    }
  }
}

@media (max-width: map-get(map-get($break-points, sm), max-width)) {
  .article {
    grid-template-rows: 160px auto;
    grid-template-columns: 100%;

    &__img {
      display: block;
      justify-self: center;
      height: 158px;
      width: 158px;

      img {
        border-radius: 0;
      }
    }
  }
}
