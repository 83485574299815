@import "src/styles/main";

.results {
  &__container {
    display: grid;
    grid-template-columns: 1fr minmax(auto, 700px) 500px 1fr;
    align-items: flex-start;
  }
  &__list {
    grid-column: 2/4;
    max-width: 1200px;
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    gap: 25px;
    margin-bottom: 70px;
    padding: 20px 25px 50px;

    &.open {
      grid-column: 2/3;
    }
  }
}

.map__container {
  display: none;
  grid-column: -1/-3;
  position: sticky;
  top: var(--headerHeight);
  height: calc(100vh - var(--headerHeight));
  padding: 20px 0;
  > div {
    height: 100%;
  }
  &.open {
    display: initial;
  }
}
.programs-not-found {
  text-align: center;
  font-family: $font-Montserrat-Bold;
  margin-top: 30px;
}
@media (max-width: map-get(map-get($break-points, md), min-width)) {
  .results {
    &__container {
      grid-template-columns: 1fr;
    }
    &__list {
      grid-column: 1/-1;
      &.open {
        grid-column: 1/-1;
      }
    }
  }
}

@media (max-width: map-get(map-get($break-points, md), max-width)) {
  .map__container {
    &.open {
      display: none;
    }
  }
}
