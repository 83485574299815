@import "src/styles/main";

.bogota {
  background: $color-gray-background;

  &__wrapper {
    max-width: 1200px;
    margin: 0 auto;
    padding: 120px 25px;
    display: flex;
  }
  &__image {
    width: 50%;
    object-fit: cover;
    border-radius: 8px;
  }
  &__content {
    padding-left: 70px;
  }
  &__title {
    font-size: 2.125rem;
    line-height: 1.35;
    font-family: $font-Montserrat-Bold;
  }
  &__subtitle {
    font-size: 1.5rem;
    line-height: 1.25;
    font-family: $font-Montserrat-SemiBold;
    margin-bottom: 18px;
  }
  &__description {
    font-size: 1.125rem;
    line-height: 1.666;
    font-family: $font-Montserrat-Regular;
    p {
      margin-bottom: 0.4em;
    }
    a {
      color: $color-pink-link;
    }
  }
}

@media (max-width: map-get(map-get($break-points, md), min-width)) {
  .bogota {
    &__wrapper {
      flex-direction: column;
      padding: 100px 25px;
    }
    &__image {
      width: 100%;
      margin-bottom: 40px;
    }
    &__content {
      padding: 0;
    }
  }
}

@media (max-width: map-get(map-get($break-points, sm), min-width)) {
  .bogota {
    &__wrapper {
      flex-direction: column;
      padding: 80px 25px;
    }
    &__image {
      width: 100%;
      margin-bottom: 30px;
    }
  }
}
