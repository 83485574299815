@import "src/styles/main";

.result-card {
  width: 100%;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  box-shadow: 0 10px 24px 0 rgba(51, 51, 51, 0.1);
  padding: 16px;
  display: flex;

  &:hover {
    border: 1px solid $color-yellow-slider;
  }

  &__image {
    width: 100%;
    max-width: 280px;
    min-height: 240px;
    object-fit: cover;
    border-radius: 5px;
    height: 300px;
    box-shadow: 0 10px 24px 0 rgba(0, 0, 0, 0.1);
  }
  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    padding-right: 10px;
  }
  &__place-date {
    margin: 8px 0 16px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    font-size: 16px;
    line-height: 1.25;
    color: $color-gray;
    i {
      color: #f42e62;
      margin-right: 8px;
      font-size: 1.25em;
    }
  }
  &__title {
    cursor: pointer;
    font-family: $font-Montserrat-SemiBold;
    font-size: 1.125rem;
    line-height: 1.222;
    color: $color-gray-title-alternative;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__price {
    font-family: $font-Montserrat-SemiBold;
    font-size: 0.875rem;
    line-height: 1.28;
    color: $color-gray-title-alternative;
  }
  &__type {
    font-size: 16px;
    font-family: $font-Montserrat-Regular;
    line-height: 1.5;
    color: $color-gray-alternative;
    cursor: pointer;
  }
  &__name {
    font-size: 16px;
    font-family: $font-Montserrat-Regular;
    line-height: 1.5;
    color: $color-gray-alternative;
    cursor: pointer;
    text-transform: capitalize;
  }
  &__description {
    font-size: 0.75rem;
    font-family: $font-Montserrat-Regular;
    line-height: 1.5;
    color: $color-gray-alternative;
    cursor: pointer;
  }
  &__actions {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: auto;
    a {
      text-decoration: none;
      cursor: pointer;
    }
    i {
      font-size: 2em;
      color: $color-whatsapp;
    }
  }
}

@media (max-width: map-get(map-get($break-points, xs), max-width)) {
  .communication-card__image {
    height: 200px;
  }
}
@media (max-width: map-get(map-get($break-points, lg), max-width)) {
  .result-card {
    &--open {
      flex-direction: column;
      .result-card__image {
        max-width: initial;
        margin-bottom: 8px;
      }
      .communication-card__content {
        padding-left: 10px;
      }
    }
  }
}
@media (max-width: map-get(map-get($break-points, md), min-width)) {
  .result-card {
    flex-direction: row;
    .result-card__image {
      max-width: 280px;
      margin-bottom: 0;
    }
    .communication-card__content {
      padding-left: 20;
    }
  }
}
@media (max-width: map-get(map-get($break-points, sm), min-width)) {
  .result-card {
    flex-direction: column;
    .result-card__image {
      max-width: initial;
      margin-bottom: 8px;
    }
    .communication-card__content {
      padding-left: 10px;
    }
  }
}
