@import "../../../styles/main";

.communication-card {
  border: 1px solid #e6e6e6;
  background: $color-white;
  border-radius: 5px;
  box-shadow: 0 10px 24px 0 rgba(255, 215, 0, 0.06);
  padding: 16px;
  cursor: pointer;
  display: flex;
  width: 100%;
  flex-direction: column;

  &-link {
    padding: 0 10px;
    &:hover {
      text-decoration: none;
    }
    display: flex;
  }

  &:hover {
    border: 1px solid $color-yellow-slider;
  }

  &__image {
    width: 100%;
    height: 130px;
    object-fit: cover;
    border-radius: 5px;
    box-shadow: 0 10px 24px 0 rgba(0, 0, 0, 0.1);
    &--contain {
      object-fit: contain;
    }
  }
  &__place-date {
    margin: 16px 0;
    display: flex;
    justify-content: space-between;
    font-size: 0.75rem;
    line-height: 1.25;
    color: $color-gray;
    i {
      color: #f42e62;
      margin-right: 8px;
      font-size: 1.25em;
    }
  }
  &__title {
    font-family: $font-Montserrat-SemiBold;
    font-size: 1.125rem;
    line-height: 1.222;
    color: $color-gray-title-alternative;
  }
  &__description {
    font-size: 0.75rem;
    font-family: $font-Montserrat-Regular;
    line-height: 1.5;
    color: $color-gray-alternative;
  }
  &__actions {
    display: flex;
    justify-content: space-between;
    margin-top: auto;

    i {
      font-size: 2em;
      color: $color-whatsapp;
    }
  }
}

@media (max-width: map-get(map-get($break-points, xs), max-width)) {
  .communication-card__image {
    height: 200px;
  }
}
