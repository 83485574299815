@import "src/styles/main";

.technical {
  background: $color-gray-background;
  display: flex;
  &__image {
    width: 50%;
    object-fit: cover;
  }
  &__content {
    width: 50%;
    padding: 70px 90px;
  }
  &__subtitle {
    font-family: $font-Montserrat-SemiBold;
    font-size: 1.5rem;
    line-height: 1.6;
  }
  &__title {
    font-family: $font-Montserrat-Bold;
    font-size: 2.12rem;
    line-height: 1.35;
    white-space: pre-line;
  }
  &__content {
    font-size: 1.125rem;
    margin-bottom: 47px;
  }
}

@media (max-width: map-get(map-get($break-points, md), max-width)) {
  .technical {
    &__content {
      padding: 60px 50px 40px;
    }
  }
}
@media (max-width: map-get(map-get($break-points, md), min-width)) {
  .technical {
    flex-direction: column;
    &__image {
      width: 100%;
    }
    &__content {
      width: 100%;
      padding: 60px 50px 40px;
    }
  }
}
