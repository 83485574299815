@import "../../../styles/main";

.history-container {
  max-width: 1200px;
  padding: 0 $padding_container_mobile;
  padding-top: 58px;
  margin: 0 auto;
  margin-bottom: 120px;

  &__timeline {
    margin-bottom: 136px;
  }
  &__explore {
    margin-bottom: 90px;
  }

  &__title {
    max-width: 700px;
    margin: 0 auto;
    font-family: $font-Montserrat-Bold;
    margin-bottom: 58px;
    padding: 0 25px;
  }
}
@media (max-width: map-get(map-get($break-points, xs), max-width)) {
  .history-container {
    &__timeline {
      margin-bottom: 50px;
    }
  }
}
