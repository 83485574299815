.tax-resident__modal {
  .modal-container {
    display: block;

    .modal-container__content {
      max-height: 80%;
      max-width: 80%;
      margin: 0 auto;
      overflow: auto;
    }
  }
}