@import "src/styles/main";

.testimonials {
  padding: 0 $padding_container_desktop 100px;
  margin-top: 100px;
  position: relative;
  text-align: center;

  &__background {
    background-image: url("../../../assets/images/testimonials/backgroundTestimonials.png");
    background-position-x: center;
    background-repeat: no-repeat;
    background-size: cover;
    left: 0;
    padding-bottom: 35px;
    position: absolute;
    height: 470px;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  &__title {
    color: $color-white;
    padding: 100px 0 65px;

    span {
      display: block;
      font-family: $font-Montserrat-Regular;
      font-size: $title_desktop;
      line-height: 30px;
      margin-bottom: 10px;

      &:not(:first-child) {
        font-size: $subtitle_desktop;
        font-weight: bold;
        line-height: 35px;
      }
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3, 280px);
    grid-column-gap: 15px;
    margin: 0 auto;
    position: relative;
    width: fit-content;
  }
}

@media (max-width: map-get(map-get($break-points, md), max-width)) {
  .testimonials {
    padding: 50px $padding_container_mobile;

    &__title {
      margin-top: 0;
      padding: 0;
      font-size: $title_mobile;

      span {
        &:not(:first-child) {
          font-size: $subtitle_mobile;
        }
      }
    }

    &__list {
      padding-top: 20px;
      grid-template-columns: repeat(2, 280px);
    }
  }
}

@media (max-width: map-get(map-get($break-points, sm), max-width)) {
  .testimonials {
    &__list {
      justify-content: center;
      grid-template-columns: 280px;
    }
  }
}