@import "src/styles/main";

.background-banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 20px 95px;
  background-size: cover;
  color: white;
  font-family: $font-Montserrat-Bold;

  &__button {
    background: $color-white;
    color: $color-blue;
    border-radius: 4px;
    padding: 14px;
    font-family: $font-Montserrat-SemiBold;
    font-size: 1.2rem;
    line-height: 1.2;
    border: none;
  }
}
