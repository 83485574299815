@import "../../../styles/main";

.section-header {
  &__subtitle {
    padding: 0 25px;
    font-family: $font-Montserrat-SemiBold;
    font-size: 1.5rem;
    line-height: 1.6;
    text-align: center;
    &--invert {
      color: $color-white;
    }
  }
  &__title {
    font-family: $font-Montserrat-Bold;
    font-size: 2.12rem;
    line-height: 1.35;
    text-align: center;
    max-width: 712px;
    padding: 0 25px;
    margin: 0 auto;
    margin-bottom: 58px;
    white-space: pre-line;
    &--full {
      max-width: initial;
    }
    &--no-margin {
      margin-bottom: 23px;
    }
    &--invert {
      color: $color-white;
    }
  }
  &__content {
    text-align: center;
    font-size: 1.125rem;
    margin-bottom: 47px;
    padding: 0 25px;
  }
  &--invert {
    color: $color-white;
  }
  &__full-content {
    max-width: 712px;
    margin: 0 auto;
    padding: 0 25px;
    span {
      color: inherit !important;
      font-family: $font-Montserrat-Regular;
      text-align: center;
      display: inline-block;
    }
  }
}
