@import "src/styles/main";

.class {
  text-align: center;
  padding: 64px 0 122px;

  &__title {
    font-family: $font-Montserrat-Medium;
    font-size: $title_desktop;
    margin-bottom: 15px;
  }

  &__subtitle {
    font-family: $font-Montserrat-Medium;
    font-size: $subtitle_desktop;

    span {
      display: block;
    }
  }

  &__text {
    font-family: $font-Montserrat-Light;
    font-size: 15px;
    line-height: 24px;
    margin: auto;
    max-width: 500px;
    padding: 20px 0 45px;

    span {
      display: block;

      &:first-child {
        margin-bottom: 15px;
      }
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;

    &--order {
      max-width: 350px;
    }

    &--title {
      font-family: $font-Montserrat-Medium;
      font-size: 20px;
      margin: 24px 0;
    }

    &--text {
      font-size: 14px;
      line-height: 22px;
    }
  }

  &__button {
    margin-top: 25px;
  }
}

@media (max-width: map-get(map-get($break-points, sm), max-width)) {
  .class {
    padding: 30px $padding_container_mobile 60px;

    &__title {
      font-size: $title_mobile;
    }

    &__subtitle {
      font-size: $subtitle_mobile;
    }

    &__text {
      padding: 20px 40px 25px;
    }

    &__list {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(3, 1fr);

      &--icon {
        margin-top: 15px;
      }
    }
  }
}