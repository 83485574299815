@import "../../../styles/main";

.history-hero {
  min-height: 650px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  &__title {
    color: $color-white;
    text-align: center;
    font-size: 3.4rem;
    line-height: 1.2;
    font-family: $font-Montserrat-Bold;
  }
}
