@import "src/styles/main";

.box-information-location {
  box-sizing: border-box;
  border: 1px solid $color-gray-border;
  border-radius: 5px;
  box-shadow: 0 10px 24px 0 rgba(0,0,0,0.06);
  margin: auto;
  padding: 14px;
  width: 270px;

  &__img {
    img {
      width: 242px;
    }
  }

  &__location {
    text-align: left;
    font-size: 12px;

    i {
      color: $color-pink-link;
      font-size: 14px;
    }

    span {
      margin-left: 8px;
      position: relative;
      top: -2px;
    }
  }

  &__title {
    font-family: $font-Montserrat-Medium;
    text-align: left;
  }

  &__text {
    text-align: left;
    font-size: 12px;
    line-height: 15px;
  }
}