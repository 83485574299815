@import "src/styles/main";

.banner-reasons {
  display: grid;
  grid-template-columns: 2fr 50% 25% auto 110px;
  grid-template-rows: 90px 1fr 90px;
  height: 600px;
  margin-bottom: 60px;

  &__content {
    background: $color-yellow;
    grid-column: 1/3;
    grid-row: 1/3;
    padding: 100px 80px 30px 130px;
    color: $color-black;
  }
  &__helper {
    background: $color-yellow;
    grid-column: 3/4;
    grid-row: 1/3;
  }
  &__image {
    grid-column: 3/-2;
    grid-row: 2/-1;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  &__subtitle {
    font-size: 1.5rem;
    line-height: 1.25;
    font-family: $font-Montserrat-SemiBold;
  }
  &__title {
    font-size: 2.125rem;
    line-height: 1.35;
    font-family: $font-Montserrat-Bold;
  }
  &__content {
    font-size: 1.125rem;
    line-height: 1.666;
    font-family: $font-Montserrat-Regular;
  }
}
.five-reasons {
  max-width: 1150px;
  margin: 0 auto 90px;

  &__title {
    font-size: 2.125rem;
    line-height: 1.35;
    font-family: $font-Montserrat-Bold;
    text-align: center;
    margin-bottom: 40px;
  }
  &__container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}
.banner-wrapper {
  margin-bottom: 94px;
}

@media (max-width: map-get(map-get($break-points, lg), max-width)) {
  .banner-reasons {
    grid-template-columns: 2fr 50% 25% auto 60px;
    grid-template-rows: 90px 1fr 90px;
    height: 700px;
    margin-bottom: 50px;

    &__content {
      padding: 80px 60px 30px 80px;
    }
  }
  .five-reasons {
    padding: 0 25px;
  }
}
@media (max-width: map-get(map-get($break-points, lg), max-width)) {
  .banner-reasons {
    grid-template-columns: 50px 1fr 50px;
    grid-template-rows: 400px 120px 120px 300px;
    height: initial;

    &__content {
      grid-column: 1/-1;
      grid-row: 1/-2;
      padding: 80px 60px 30px;
    }
    &__helper {
      display: none;
    }
    &__image {
      grid-column: 2/-2;
    }
  }
  .five-reasons {
    padding: 0 15px;
  }
}

@media (max-width: map-get(map-get($break-points, xs), max-width)) {
  .banner-reasons {
    grid-template-columns: 25px 1fr 25px;
    grid-template-rows: 400px 120px 120px 270px;
    height: initial;

    &__content {
      grid-column: 1/-1;
      grid-row: 1/-2;
      padding: 60px 25px 30px;
    }
    &__helper {
      display: none;
    }
    &__image {
      grid-column: 1/-1;
    }
  }
}
