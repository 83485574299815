@import "../../../styles/main";

.university-offer {
  margin: 76px auto 76px;

  &__container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(255px, 1fr));
    grid-auto-rows: auto;
    gap: 27px 25px;
    margin-bottom: 82px;
  }

  &__button {
    display: flex;
    justify-content: center;
  }
}
.communication-link {
  font-family: 0.875;
  color: $color-blue;
  font-family: $font-Montserrat-SemiBold;
}

@media (min-width: 1440px) {
  .university-offer {
    &__container {
      grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
      gap: 32px 29px;
    }
  }
}
