@import "../../../styles/main";

.best-spanish {
  max-width: 700px;
  margin: 0 auto;
  padding-top: 80px;
  padding-bottom: 120px;

  h2 {
    max-width: 540px;
  }

  &__scholarships {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 55px;
  }
}

@media (max-width: map-get(map-get($break-points, sm), min-width)) {
  .best-spanish {
    &__scholarships {
      grid-template-columns: 1fr;
      gap: 40px;
      padding: 0 25px;
    }
  }
}
