@import "src/styles/main";

.showVideo {
  padding: 100px 0 200px;
  color: white;

  h1 {
    font-size: 42px;
    font-weight: bold;
    line-height: 60px;
    display: block;
    text-align: center;
    font-family: $font-Montserrat-Regular;
  }

  span {
    display: block;
    text-align: center;
    font-family: $font-Montserrat-Regular;

    &:first-child {
      font-size: 42px;
      font-weight: bold;
      line-height: 60px;
    }

    &:last-child {
      font-size: 30px;
    }
  }
  &__icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
  }
  &__icon-text {
    margin: 0;
    margin-left: 12px;
    font-size: 16px;
  }
}

@media (max-width: map-get(map-get($break-points, sm), max-width)) {
  .showVideo {
    padding: 70px $padding_container_mobile 150px;

    span {
      &:first-child {
        font-size: 32px;
        line-height: 30px;
      }

      &:last-child {
        font-size: 25px;
        margin-top: 10px;
      }
    }
  }
}
