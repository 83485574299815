@import "src/styles/main";

.for-foreigners {
  background: $color-gray-background;
  padding: 120px 25px;

  &__wrapper {
    max-width: 1200px;
    margin: 0 auto 80px;
    display: flex;

    &--invert {
      flex-direction: row-reverse;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__image {
    width: 50%;
    object-fit: contain;
    border-radius: 8px;
    background: white;
  }
  &__content {
    padding-left: 70px;

    &--invert {
      padding-right: 70px;
      padding-left: 0;
    }
    a {
      color: $color-pink-link;
    }
  }
  &__title {
    font-size: 2.125rem;
    line-height: 1.35;
    font-family: $font-Montserrat-Bold;
  }
  &__subtitle {
    font-size: 1.5rem;
    line-height: 1.25;
    font-family: $font-Montserrat-SemiBold;
    margin-bottom: 18px;
  }
  &__description {
    font-size: 1.125rem;
    line-height: 1.666;
    font-family: $font-Montserrat-Regular;
    white-space: pre-line;
  }
}

@media (max-width: map-get(map-get($break-points, md), min-width)) {
  .for-foreigners {
    padding: 100px 25px;

    &__wrapper {
      flex-direction: column;
      margin: 0 auto 60px;
    }
    &__image {
      width: 100%;
      margin-bottom: 40px;
    }
    &__content {
      padding: 0;
    }
  }
}

@media (max-width: map-get(map-get($break-points, sm), min-width)) {
  .for-foreigners {
    padding: 80px 25px;
    &__wrapper {
      flex-direction: column;
      margin: 0 auto 40px;
    }
    &__image {
      width: 100%;
      margin-bottom: 30px;
    }
  }
}
