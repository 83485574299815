$color-black: #000000;
$color-black-title: #171717;

$color-blue: #000080;
$color-blue-hover: #4c4c8a;
$color-blue-background: #e5e5f3;
$color-blue-twitter: #40c9ff;
$color-blue-facebook: #3b5998;

$color-gray-overlay: #2c2c2c; // Opacity: 54%
$color-gray-title: #2c2e30; // Text title
$color-gray-title-alternative: #151f2f; // Text title alternative
$color-gray-dark: #666666;
$color-gray: #999999; // Text content
$color-gray-alternative: #5c5f64; // Text content
$color-gray-light: #cccccc;
$color-gray-border: #e7eaee;
$color-gray-input: #bfbfbf;
$color-gray-placeholder: #909090;
$color-white-background: #FAFBFC;

$color-gray-timeline-line: #979797;
$color-gray-background: #f3f4f6;
$color-gray-hr: #e5e5e5;

$color-red-pinterest: #c8232c;
$color-pink-link: #f42e62;

$color-yellow: #ffcf2a;
$color-yellow-slider: #ffd700;
$color-white: #ffffff;
$color-whatsapp: #40c351;
