@import "../../../styles/main";

.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  &__container {
    max-width: 1230px;
    padding: 0 15px;
    margin: 0 auto;
  }

  &--solid {
    background: $color-blue;
  }
}
