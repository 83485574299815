@import "src/styles/main";

.requirements {
  max-width: 1200px;
  padding: 120px 25px 100px;
  margin: 0 auto;

  &__list {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: map-get(map-get($break-points, md), min-width)) {
  .requirements {
    padding: 100px 25px 80px;
    &__list {
      flex-wrap: wrap;
    }
  }
}

@media (max-width: map-get(map-get($break-points, md), min-width)) {
  .requirements {
    padding: 70px 25px 50px;
  }
}
