@import "src/styles/main";

.image-text-interspersed {
  padding: 80px 25px 90px;

  &--gray {
    background: $color-gray-background;
  }

  &__wrapper {
    max-width: 1200px;
    margin: 0 auto;
  }

  &__content {
    display: flex;
    padding: 30px 0;
  }
  &__list {
    width: 50%;
    padding: 0 32px 60px;
    font-family: $font-Montserrat-Regular;
    font-size: 1.125rem;
    line-height: 1.66;
    &--last {
      padding-top: 60px;
    }
  }
  &__image {
    width: 50%;
    object-fit: cover;
    padding: 0 32px;
  }
  &__btn {
    margin-top: 20px;
  }
  &__subtitle {
    font-size: 1.5rem;
    line-height: 1.2;
    font-family: $font-Montserrat-SemiBold;
  }
  &__title {
    font-size: 2.125rem;
    line-height: 1.23;
    font-family: $font-Montserrat-Bold;
  }
}

@media (max-width: map-get(map-get($break-points, md), min-width)) {
  .image-text-interspersed {
    padding: 70px 25px 80px;
    &__list {
      padding: 0 16px 30px;
    }
    &__image {
      padding: 0 16px;
    }
  }
}
@media (max-width: map-get(map-get($break-points, sm), min-width)) {
  .image-text-interspersed {
    padding: 50px 25px 60px;
    &__content {
      flex-direction: column;
      padding: 0;
    }
    &__list {
      width: 100%;
      order: 2;
      padding-top: 20px;
    }
    &__image {
      width: 100%;
    }
  }
}
