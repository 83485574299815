@import "src/styles/main";

.login {
  margin-bottom: 50px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 100%;

  &__forgotPassword {
    cursor: pointer;
  }

  &__sign-up {
    span {
      font-size: 14px;

      &:last-child {
        cursor: pointer;
        padding-left: 5px;
        color: $color-pink-link;
      }
    }
  }

  &__title {
    font-size: 1.5rem;
    line-height: 1.25;
    font-family: $font-Montserrat-SemiBold;
    color: $color-black;
    margin-bottom: 30px;
  }

  &__img {
    position: relative;

    img {
      height: 1024px;
      width: 100%;
      object-fit: cover;
    }

    &--text {
      position: absolute;
      text-align: center;
      top: 50%;
      width: 100%;

      .title {
        color: $color-white;
        font-size: 26px;
        margin-bottom: 0;
        top: 0;
      }

      .subtitle {
        color: $color-white;
        font-size: 22px;

        span {
          font-family: $font-Montserrat-Light;
          font-weight: bold;
          display: block;
          line-height: 25px;
        }
      }
    }
  }

  &__form {

    &--logo {
      padding: 20px 50px 100px;
    }

    &--form {
      .form {
        padding: 20px 50px;

        u {
          cursor: pointer;
        }
      }
    }

    &--terms {
      span {
        padding-left: 10px;
      }
    }
  }

  &__checkbox {
    cursor: pointer;
  }

  &__button {
    border: none;
    background: $color-blue;
    color: $color-white;
    cursor: pointer;
    outline: none;
    padding: 15px 0;
    border-radius: 5px;
    font-family: $font-Montserrat-SemiBold;
    font-size: 1.25rem;
    line-height: 1.2;
    margin-top: 65px;
    width: 100%;

    &:disabled {
      background-color: $color-gray-dark;
    }
  }
}

@media (max-width: map-get(map-get($break-points, sm), max-width)) {
  .login {
    grid-template-columns: 100%;
    grid-template-rows: repeat(2, auto);
    margin-bottom: 10px;

    &__img {
      height: auto;
      width: 100%;
      object-fit: cover;
    }

    &__form {
      &--logo {
        padding: 40px 50px 20px;
      }
    }

    &__button {
      margin-top: 20px;
    }
  }
}