@import "src/styles/main";

.rankings {
  background: $color-gray-background;
  padding: 80px 25px 86px;
  text-align: center;

  &__button {
    margin-top: 30px;
  }
}

@media (max-width: map-get(map-get($break-points, md), min-width)) {
  .rankings {
    padding: 60px 25px 66px;
  }
}
