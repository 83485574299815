@import "src/styles/main";

.hero-study-work {
  height: 668px;
  padding: var(--headerHeight) 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &__title {
    font-family: $font-Montserrat-Bold;
    font-size: 3.1875;
    line-height: 1.196;
    color: $color-white;
    max-width: 800px;
    text-align: center;
  }
}

@media (max-width: map-get(map-get($break-points, xs), max-width)) {
  .hero-study-work {
    height: 300px;
  }
}
