@import "../../../styles/main";

.slider-yellow-box {
  margin: 105px 0 50px;

  &__container {
    display: grid;
    grid-template-columns: 80px 1fr 150px 1fr;
    grid-template-rows: 70px 1fr 70px;
    height: 500px;
    overflow: hidden;
  }
  &__content {
    background: $color-yellow-slider;
    grid-row: 2/-2;
    grid-column: 2/4;
    padding: 0 50px 0 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: $color-black;
    z-index: 9;
  }
  &__image {
    grid-row: 1/-1;
    grid-column: -1/-3;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__button {
    grid-row: -1/-2;
    grid-column: 2/3;
    display: flex;
    justify-content: flex-end;
    align-self: center;
    padding: 10px;
  }
  &__title {
    font-size: 4.02rem;
    font-family: $font-Montserrat-Bold;
    text-transform: uppercase;
    margin: 0;
    line-height: 1.2;
  }
  &__subtitle {
    font-size: 1.5rem;
    font-family: $font-Montserrat-Bold;
    margin: 0;
  }
  &__text {
    font-family: $font-Montserrat-Regular;
    font-size: 1.2rem;
    line-height: 1.5;
  }

  &__dots {
    display: flex;
    justify-content: center;
    padding: 50px 0;
  }
  &__dot {
    border: none;
    background: lightgray;
    min-height: 10px;
    min-width: 10px;
    border-radius: 50%;
    margin: 0 7px;
    outline: 0px;
    cursor: pointer;

    &--active {
      background: black;
      transform: scale(1.2);
    }
  }
}

.fadeFromLeft {
  animation: fadeFromLeft 0.9s ease 0s 1 normal forwards;
}
.fadeFromRight {
  animation: fadeFromRight 0.9s ease 0s 1 normal forwards;
}
.fadeFromBottom {
  animation: fadeFromBottom 0.9s ease 0s 1 normal forwards;
}

@keyframes fadeFromLeft {
  from {
    opacity: 0;
    transform: translateX(-25%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes fadeFromRight {
  from {
    opacity: 0;
    transform: translateX(25%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes fadeFromBottom {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: map-get(map-get($break-points, md), max-width)) {
  .slider-yellow-box {
    // margin: 105px 0 110px;

    &__container {
      grid-template-columns: 60px 1fr 210px 1fr;
    }
    &__content {
      grid-row: 1/-2;
      padding: 0 40px 0 65px;
    }
    &__dots {
      padding: 30px 0;
    }
  }
}
@media (max-width: map-get(map-get($break-points, xs), max-width)) {
  .slider-yellow-box {
    &__container {
      grid-template-columns: 30px 1fr 30px;
      grid-template-rows: 120px 1fr 50px 70px;
      height: 600px;
    }
    &__content {
      grid-column: 2/-2;
      padding: 0 35px;
    }
    &__image {
      grid-column: 1/-1;
      grid-row: 1/-3;
    }
    &__button {
      grid-column: 1/-1;
      justify-content: center;
    }
    &__dots {
      padding: 20px 0;
    }
  }
}
