@import "../../../styles/main";

.timeline {
    display: grid;
    grid-template-columns: 1fr 1px 1fr;
    gap: 0 34px;

    &__line {
        grid-column: 2/3;
        grid-row: 1/-2;
        background-image: linear-gradient($color-gray-timeline-line 65%, rgba(255, 255, 255, 0) 0%);
        background-position: right;
        background-size: 1px 10px;
        background-repeat: repeat-y;
    }

    &__empty {
        grid-column: 2/3;
        grid-row: -1/-2;
    }
}
@media (max-width: map-get(map-get($break-points, xs), max-width)) {
    .timeline {
        gap: 0 17px;
    }
}

@media (max-width: map-get(map-get($break-points, xxs), max-width)) {
    .timeline {
        gap: 0 13px;
    }
}
