@import "src/styles/main";

.where-to-start__hero {
  height: 600px;
  display: grid;
  place-content: center;
  margin-bottom: 90px;
}

@media (max-width: map-get(map-get($break-points, xs), max-width)) {
  .where-to-start__hero {
    height: 300px;
  }
}
