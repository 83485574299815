@import "src/styles/main";

.select {
  border-radius: 3px;
  height: 40px;
  width: 100%;
  border: 1px solid $color-gray-input;
  padding: 5px 15px;
  margin: 0 15px 0 0;
  outline: none;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;

  &--active {
    border: 1px solid $color-yellow-slider;
  }
  &__options {
    background: white;
    z-index: 1;
    padding: 0;
    list-style: none;
    position: absolute;
    width: 100%;
    top: calc(100% + 1px);
    left: 0;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.16);
    overflow: scroll;
    max-height: 300px;
  }
  &__option {
    padding: 10px 15px;
    cursor: pointer;

    &:hover,
    &--active {
      background: $color-gray-background;
    }
  }
  &__current {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 0.875rem;
    font-family: $font-Montserrat-Medium;
    margin: 0;
    border: 0;

    &--selected {
      color: $color-blue;
      font-family: $font-Montserrat-Regular;
    }
    &-text {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 130px;
      display: inline;
    }
    span:not(.select__current-text) {
      display: flex;
      align-items: center;

      i {
        font-size: 1.2rem;
        color: $color-blue;
        margin-right: 8px;
        position: initial;
      }
    }
    i {
      position: absolute;
      right: 8px;
    }
  }
  &--invalid {
    border: 1px solid red;
  }
}
