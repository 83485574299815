@import "src/styles/main";

.searcher {
  background-color: $color-white;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  margin: 0 auto;
  padding: 24px;
  width: min-content;

  &--message {
    margin-bottom: 20px;
    font-family: $font-Montserrat-Light;
    font-size: 20px;
    font-weight: bold;
    line-height: 29px;
  }

  &--filter {
    display: grid;
    grid-template-columns: repeat(6, max-content);

    .generic-button {
      border-radius: 0 5px 5px 0;
      font-size: 14px;
      height: 56px;
    }
  }

  &-helper {
    padding-bottom: 32px;
  }
}

@media (max-width: map-get(map-get($break-points, md), max-width)) {
  .searcher {
    margin: 0 $padding_container_mobile;
    width: auto;

    &--message {
      margin-bottom: 18px;
    }

    &--filter {
      display: grid;
      grid-template-columns: repeat(2, auto);
      grid-gap: 20px 10px;

      .generic-button {
        border-radius: 5px;
        width: 100%;
      }
    }
  }
}

@media (max-width: map-get(map-get($break-points, xs), max-width)) {
  .searcher {
    &--filter {
      grid-template-columns: auto;
    }
  }
}
