@import "src/styles/main";

video {
  width: 600px;
}

@media (max-width: map-get(map-get($break-points, sm), max-width)) {
  video {
    width: 300px;
  }
}
