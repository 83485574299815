@import "src/styles/main";

.city-u {
  border: 1px solid $color-gray-input;
  display: flex;
  border-radius: 4px;
  margin: 10px 0;
  padding: 15px;
  cursor: pointer;
  box-shadow: 0 10px 24px 0 rgba(255, 207, 42, 0.04);

  &:hover {
    border: 1px solid $color-yellow;
  }

  &__image {
    min-height: 100%;
    max-width: 160px;
    min-width: 160px;
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
    overflow: hidden;
  }
  &__content {
    padding-left: 20px;
  }
  &__title {
    font-family: $font-Montserrat-SemiBold;
    font-size: 20px;
    line-height: 1.22;
    margin-bottom: 4px;
  }
  &__description {
    font-size: 16px;
    line-height: 1.333;
  }
}

@media (max-width: map-get(map-get($break-points, md), min-width)) {
  .city-u {
    flex-direction: column;
    &__content {
      padding-left: 0;
    }
    &__title {
      margin-top: 12px;
    }
    &__image {
      max-width: 100%;
    }
  }
}
