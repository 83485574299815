@import "src/styles/main";

.scholarships-hero {
  height: 768px;
}
.scholarships {
  padding-bottom: 90px;
}

@media (max-width: map-get(map-get($break-points, xs), max-width)) {
  .scholarships-hero {
    height: 300px;
  }
}
