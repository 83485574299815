@import "src/styles/main";

.link-list {
  color: $color-white;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 15px 0 15px;

  &__option {
    display: contents;
  }

  &__options {
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.16);
    color: $color-black;
    display: none;
    font-size: 0.75rem;
    line-height: 1.666;
    height: auto;
    left: 0;
    padding: 8px 0;
    position: absolute;
    top: 30px;
    width: 100%;
    z-index: 1;

    div {
      margin: 5px 0;
      padding: 8px 16px;

      &:hover {
        background-color: $color-gray-background;
      }
    }
  }
}
@media (max-width: map-get(map-get($break-points, lg), max-width)) {
  .link-navigation {
    padding: 0 30px;
  }
}

@media (max-width: map-get(map-get($break-points, lg), min-width)) {
  .link-list {
    display: none;
    flex-direction: column;
    height: calc(100vh - 100px);
    justify-content: flex-start;

    &__options {
      color: $color-white;
      position: static;
      background: none;
      box-shadow: none;
      div {
        &:hover {
          background: rgba(0, 0, 0, 0.16);
        }
      }
    }
    &--show {
      display: flex;
    }
  }
}
