@import "src/styles/main";

.region-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  grid-gap: 20px;
  padding: 30px 20px;

  &__image {
    align-self: center;
    justify-self: center;

    img {
      width: 100%;
      position: relative;
    }
  }

  &__text {
    justify-self: center;
    align-self: center;

    &--content {
      .regions-title {
        font-family: $font-Montserrat-Bold;
        font-size: 16px;
      }

      .regions-text {

      }
    }
  }

  &:nth-child(odd) {
    .region-list__image {
      grid-column: 2/3;
      grid-row: 1/2;
    }

    .region-list__text {
      grid-column: 1/2;
      grid-row: 1/2;
    }
  }
}

@media (max-width: map-get(map-get($break-points, sm), max-width)) {
  .region-list {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, auto);


    &__image {
      width: 100%;

      img {
        width: inherit;
      }
    }

    &__text {

    }

    &:nth-child(odd) {
      .region-list__image {
        grid-column: 1/2;
        grid-row: 1/2;
      }

      .region-list__text {
        grid-column: 1/2;
        grid-row: 2/3;
      }
    }
  }
}
