@import "src/styles/main";

.box-information-simple {
  box-sizing: border-box;
  border: 1px solid $color-gray-light;
  border-radius: 4px;
  padding: 24px;
  max-width: 364px;
  width: fit-content;

  &__img {
    img {
      max-width: 315px;
      width: 100%;
    }
  }

  &__title {
    font-family: $font-Montserrat-Regular;
    font-size: 20px;
    line-height: 30px;
    padding: 20px 0;
    text-align: left;
  }

  &__text {
    font-size: 14px;
    line-height: 20px;
    text-align: left;
  }

  &:hover {
    cursor: pointer;
    border: 1px solid $color-yellow-slider;
  }

  &__modal {
    display: block;
    overflow: auto;
    padding: 30px;
    width: 100%;
    height: 100%;

    p {
      display: block;
    }
  }
}

@media (max-width: map-get(map-get($break-points, md), max-width)) {
  .box-information-simple {
    padding: 12px;
  }
}
