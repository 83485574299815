@import "src/styles/main";

@import "~video-react/styles/scss/video-react"; // or import scss

.slick-slider-testimonial {
  width: 100%;

  .slick-initialized.slick-slider {
    .slick-list .slick-track .slick-slide {
      > div {
        display: flex;
        justify-content: center;
        margin: auto;
        width: fit-content;
      }
    }
  }

  .slick-slider.slick-initialized {
    position: relative;

    .slick-arrow.slick-next {
    }
  }
}

.testimonial {
  text-align: left;
  border: 1px solid $color-gray-border;
  box-shadow: 0 2px 6px 0 rgba(44, 46, 48, 0.11);
  border-radius: 5px;
  display: grid !important;
  grid-template-rows: 350px min-content min-content auto;
  height: 496px;
  width: 352px !important;
  z-index: 1;

  &__video {
    width: 300px;
    height: 200px;

    .modal-container__content {
      padding: 0;
      width: 300px;
      height: 200px;
    }
  }

  &__img {
    position: relative;

    img {
      position: absolute;
      border-radius: 5px 5px 0 0;
      height: 350px;
      top: -1px;
      left: -1px;
      width: 352px;
    }
  }

  &__play-icon {
    align-items: center;
    border-radius: 50%;
    border: 5px solid $color-white;
    cursor: pointer;
    display: grid;
    position: inherit;
    color: $color-white;
    margin: 0 auto;
    height: 45px;
    top: 50%;
    width: 45px;
    z-index: 2;

    i {
      display: grid;
      justify-content: center;
      font-size: 30px;
      margin: 0 auto;
      width: fit-content;
    }
  }

  &__name {
    font-family: $font-Montserrat-Regular;
    font-weight: bolder;
    margin-left: 10px;
  }

  &__country {
    font-family: $font-Montserrat-Regular;
    color: $color-gray;
    font-size: 13px;
    margin-left: 10px;
  }

  &__text {
    color: $color-black;
    margin-left: 10px;
    font-family: $font-Montserrat-Regular;
    font-size: 1.125rem;
    line-height: 1.66;
    a {
      color: $color-pink-link;
    }
  }

  &__right,
  &__left {
    align-items: center;
    background-color: $color-white;
    border: 1px;
    border-radius: 50%;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.27);
    cursor: pointer;
    display: grid;
    justify-content: center;
    height: 60px;
    position: absolute;
    top: 50%;
    transition-duration: 0.8s;
    width: 60px;
    z-index: 2;

    &:hover {
      transition-duration: 0.8s;
      background-color: $color-gray-background;
    }
  }

  &__left {
    left: 20px;
  }

  &__right {
    right: 20px;
  }
}

@media (max-width: map-get(map-get($break-points, sm), max-width)) {
  .testimonial {
    &__video {
      .modal-container__content {
        width: 100%;
      }
    }

    &__left {
      left: 0;
    }

    &__right {
      right: 0;
    }
  }
}
