@import "src/styles/main";

.sponsors {
  background-color: $color-white-background;
  margin: 50px $padding_container_desktop;
  padding: 50px 50px 20px;

  .slick-initialized.slick-slider {
    .slick-list .slick-track .slick-slide{
      > div {
        display: flex;
        justify-content: center;
        margin: auto;
        width: fit-content;
      }
    }
  }
}

.slick-slide img {
  cursor: pointer;
}

@media (max-width: map-get(map-get($break-points, sm), max-width)) {
  .sponsors {
    margin: 25px $padding_container_mobile;
    padding: 25px 0 10px;
  }
}