@import "src/styles/main";

.region-title {
  margin: 0 auto;
  max-width: 1000px;
  padding: 30px;
  width: 100%;
}

@media (max-width: map-get(map-get($break-points, sm), max-width)) {
  .region-title {
    span {
      font-size: 30px !important;
    }
  }
}