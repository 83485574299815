@import "src/styles/main";

.steps {
  &__container {
    max-width: 1200px;
    padding: 0 25px;
    margin: 0 auto;
    text-align: center;
  }
  &__title {
    font-family: $font-Montserrat-Bold;
    margin-bottom: 80px;
  }
  &__subtitle {
    margin: 40px 0 20px;
    font-size: 1.5rem;
    line-height: 1.2;
    font-family: $font-Montserrat-SemiBold;
  }
  &__list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}
.modal-faq {
  overflow-y: auto;
}
