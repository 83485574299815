@import "../../../../styles/main";

.select {
  border-radius: 5px;
  height: 50px;
  width: 100%;
  border: 1px solid $color-gray-input;
  padding: 10px!important;
  margin-bottom: 15px!important;
  outline: none;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;

  &--active {
    border: 1px solid $color-yellow-slider;
  }
  &__options {
    background: white;
    padding: 0;
    list-style: none;
    position: absolute;
    width: 100%;
    top: calc(100% + 1px);
    left: 0;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.16);
    overflow: hidden;
  }
  &__option {
    padding: 10px 15px;
    cursor: pointer;

    &:hover,
    &--active {
      background: $color-gray-background;
    }
  }
  &__current {
    width: 100%;
    display: flex;
    justify-content: space-between;

    &--selected {
      color: $color-blue;
      font-family: $font-Montserrat-Regular;
    }
    i {
      font-size: 1.2rem;
    }
  }
  &--invalid {
    border: 1px solid red;
  }
}
