@import "./src/styles/main";

.articles {
  text-align: center;
  padding: 0 150px;

  &__title {
    margin-bottom: 120px;

    span {
      display: block;

      &:first-child {
        font-family: $font-Montserrat-Regular;
        line-height: 29px;
        font-size: $title_desktop;
        margin: 10px 0;
      }

      &:not(:first-child) {
        color: $color-gray-title;
        font-family: $font-Montserrat-Regular;
        font-size: $subtitle_desktop;
        font-weight: bold;
        line-height: 46px
      }
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 25px 30px;
  }
}

@media (max-width: map-get(map-get($break-points, md), max-width)) {
  .articles {
    padding: 0 $padding_container_desktop;

    &__title {
      margin-bottom: 60px;

      span {
        &:first-child {
          font-size: $title_mobile;
        }

        &:not(:first-child) {
          font-size: $subtitle_mobile;
        }
      }
    }

    &__list {
      display: grid;
      grid-template-columns: 100%;
      grid-gap: 20px;
    }
  }
}